import React, {useState, useEffect}  from 'react'

import Sidebar from '../Home/Components/Sidebar/Sidebar'
import Header from '../Home/Components/Header/Header'
import Footer from '../Home/Components/Footer/Footer'
import CorePolicy from './Components/CorePolicy/CorePolicy'
function Policy() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>

            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Header toggle={toggle}/>
            <CorePolicy />
            <Footer />
            
        </>
    )
}

export default Policy
